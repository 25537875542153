.ag-theme-alpine {
    --ag-header-height: 40px;
    --ag-header-foreground-color: #ffffff; // White text
    --ag-header-background-color: #374151; // Dark gray/blue background
    --ag-row-hover-color: #F3F4F6;
    --ag-font-size: 14px;
    --ag-row-height: 48px;
    --ag-selected-row-background-color: #E5E7EB;
    --ag-odd-row-background-color: #F9FAFB;
    --ag-row-border-color: #E5E7EB;

    // Enable text selection
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    .ag-header-cell {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.05em;
        background-color: var(--ag-header-background-color);
        color: var(--ag-header-foreground-color);
    }

    .ag-paging-panel {
        height: 48px;
        padding: 0 12px;
        color: var(--ag-header-foreground-color);
        border-top: 1px solid var(--ag-border-color-1);

        .ag-paging-button {
            cursor: pointer;
            padding: 4px 8px;
            margin: 0 2px;
            border: 1px solid var(--ag-border-color);
            border-radius: 4px;
            transition: all 0.2s;

            &:hover:not(.ag-disabled) {
                background-color: var(--ag-row-hover-color);
                border-color: var(--ag-border-color-2);
            }

            &.ag-disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        .ag-paging-description {
            padding: 0 8px;
        }

        .ag-paging-row-summary-panel {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .ag-paging-page-summary-panel {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .ag-row {
        &.ag-row-odd {
            background-color: var(--ag-odd-row-background-color);
        }

        &:hover {
            background-color: var(--ag-row-hover-color);
        }

        &.ag-row-selected {
            background-color: var(--ag-selected-row-background-color);
        }
    }

    .ag-cell {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    // Override AG Grid's default selection behavior
    .ag-cell-focus {
        user-select: text;
        &:not(.ag-cell-range-selected):focus-within {
            border: none !important;
            outline: none;
        }
    }
}
